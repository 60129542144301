.page-heading {
  text-align: center;
}

.tos-section {
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: var(--clr-black-a80);
  margin-bottom: 1rem;
}

.tos-section:last-of-type {
  margin-bottom: 0;
}

.tos-section__heading {
  text-align: center;
}

.tos-section__item {
  margin-bottom: 1em;
}
