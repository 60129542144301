.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--header-height);
  display: flex;
  background-color: var(--clr-black-a85);
  backdrop-filter: blur(2px);
  flex-direction: row;
  justify-content: center;
  z-index: 1000;
}

.header__wrapper {
  width: 100%;
  max-width: 1000px;
  display: flex;
}

.nav {
  display: flex;
  margin-left: auto;
}

.nav-list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-list__item {
  margin-right: 1rem;
}

.nav-list__link {
  transition: color 250ms ease-in;
}

.nav-list__link--active {
  color: var(--clr-bg);
}

.nav-list__link:hover,
.nav-list__link:active {
  color: var(--clr-bg);
}