:root {
  /* Colors */
  --clr-white: #FFFFFF;
  --clr-text: #a7a7a7;
  --clr-bg: #AA2923;
  --clr-black: #000000;
  --clr-black-a85: #000000cb;
  --clr-black-a80: #000000cc;
  --clr-gray-200: #DEDEDE;
  --clr-gray-400: #9F9F9F;
  --clr-error: #D80909;

  /* Brake points */
  --width-desktop: 1120px;

  /* Fonts */
  --fs-heading: 30px;
  --fs-title: 26px;
  --fs-large: 16px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
}

html {
  /* width: 100%; */
  background-color: blanchedalmond;
}

body {
  /* min-height: 100vh; */
  /* max-width: 320px; */
  margin: 0 auto;

  /* font-family: 'Helvetica', sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: var(--clr-text);
  font-feature-settings: 'tnum' on, 'lnum' on;
  background-color: var(--clr-white);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0.5rem;
}
::-webkit-scrollbar-thumb {
  background-color: var(--clr-black-a85);
  border-radius: 1rem;
}

/* Normalize */
a {
  color: var(--clr-text);
  text-decoration: none;
}

img,
video {
  display: block;
  max-width: 100%;
  height: auto;
}

textarea {
  resize: none;
}

/* chrome autofill background removal */
input:-webkit-autofill {
  box-shadow: inset 0 0 0 1000px white;

  -webkit-text-fill-color: black;
}

/* firefox placeholder \ invalid fix + ios bdrs */
input {
  border-radius: 0;
}

input::placeholder {
  opacity: 1;
}

input:invalid {
  box-shadow: none;
}

textarea {
  border-radius: 0;
}

textarea::placeholder {
  opacity: 1;
}

textarea:invalid {
  box-shadow: none;
}

select {
  border-radius: 0;
}

/* chrome search X removal */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  appearance: none;
}

/* input[number] arrows removal */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;

  appearance: none;
}

input[type='number'] {
  appearance: textfield;
}

/* ios button \ inputs reset */
select,
textarea,
input:matches([type='email'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='url']) {
  appearance: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: none;
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;

  clip: rect(0 0 0 0);
}

.prevent-scroll {
  overflow: hidden;
}