.examples__gallery {
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.examples__image-container {
  flex-basis: 100%;
  flex-grow: 1;
}

@media (min-width: 500px) {
  .examples__image-container {
    flex-basis: 40%;
  }
}

.examples__image {
  width: 100%;
  height: auto;
}