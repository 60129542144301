.products {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.products__item {
  flex: 320px 1 0;
}

.item {
  background-color: var(--clr-black-a80);
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.item__heading {
  font-weight: bold;
  text-align: center;
}

.item__image {
  margin: 0 auto;
  margin-bottom: 1rem;
}

.item__description {
  margin: 0.2em 0;
  text-align: left;
  padding: 0 1rem;
}

.item__highlight {
  color: var(--clr-bg);
}

.item__special {
  padding-top: 0.5rem;
  font-weight: bold;
  text-align: center;
  font-size: 1.4em;
  margin-top: auto;
  margin-bottom: 0;
}

.item__price {
  margin-top: 0.3rem;
  text-align: center;
  font-weight: bold;
}
