.page-wrapper {
  background-color: var(--clr-bg);
  background-image: url('assets/image/pattern.png');
  background-repeat: repeat;
  background-attachment: scroll;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero {
  background-color: var(--clr-black-a85);
  backdrop-filter: blur(2px);
  max-width: 95%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

@media (min-width: 768px) {
  .hero {
    display: grid;
    grid-template-columns: minmax(240px, max-content) 2fr 1fr;
    max-width: 1000px;
  }
}

.hero-avatar {
  flex-shrink: 1;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.hero-avatar > img {
  height: clamp(240px, 64vh, 365px);
  max-height: 480px;
  width: auto;
  margin: 0 auto;
  aspect-ratio: 47 / 67;
  object-fit: contain;
}

@media (min-width: 768px) {
  .hero-avatar > img {
    height: clamp(240px, 70vh, 769px);
    max-height: 480px;
    width: auto;
    margin: 0 auto;
    aspect-ratio: 47 / 67;
    object-fit: contain;
  }
}

.about {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .about {
    padding: 0.5rem;
    position: relative;
  }

  .about::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 2px;
    height: 100%;
    background-color: var(--clr-bg);
  }
}

.about__header {
  line-height: 1.5em;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}

.about__header--image {
  /* height: 100px; */
  width: auto;
  position: relative;
  align-self: center;
}

.about__text {
  position: relative;
  padding: 1rem;
}

.about__text > p {
  margin-top: 0;
}

.about__header::after,
.about__text::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--clr-bg);
}

@media (min-width: 768px) {
  .about__text::after {
    display: none;
  }
}


.navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* align-items: center; */
  padding: 1rem;
}

@media (min-width: 768px) {
  .navigation {
    align-content: flex-start;
  }
}

.navigation__title {
  flex-basis: 100%;
  text-align: center;
}

.navigation__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.navigation__list--center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.navigation__link {
  display: inline-block;
  height: 2rem;
  align-items: center;
}

.navigation__link--icon {
  display: flex;
}

.navigation__link:hover,
.navigation__link:focus-within {
  color: var(--clr-bg);
  fill: var(--clr-bg);
  transition: color 250ms;
}

.icon-link {
  display: inline-block;
  margin-right: 0.5rem;
}

.icon {
  width: 2rem;
  height: 2rem;
  fill: var(--clr-gray-200);
  transition: fill 250ms ease;
}

.icon:hover {
  fill: var(--clr-bg);
}

.copyright {
  align-self: flex-start;
  margin-top: auto;
  margin-left: 1rem;
  font-size: 0.7rem;
}