.page-heading {
  text-align: center;
  line-height: 1.2em;
  background-color: var(--clr-black-a80);
  width: 100%;
  border-radius: 0.5rem;
  padding: 0.5rem;
}


.link {
  filter: contrast(2.5);
}

.link:hover {
  filter: brightness(10);
}

h2 {
  margin-top: 2rem;
}

.page-order {
  margin-top: 2em;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: var(--clr-black-a80);
}

.page-order > h2 {
  text-align: center;
}

.external-link {
  color: var(--clr-bg);
}