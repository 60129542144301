.wrapper {
  --header-height: 60px;
  --footer-height: 60px;
  height: 100vh;
  display: grid;
  grid-template-rows: var(--header-height) 1fr var(--footer-height);
  grid-template-columns: 1fr;
  background-color: var(--clr-bg);
  background-image: url('assets/image/pattern.png');
  background-repeat: repeat;
  background-attachment: scroll;
  height: 100vh;
  width: 100%;
}

.main {
  overflow-y: auto;
  padding: 1rem;
  padding-top: var(--header-height);
  padding-bottom: var(--footer-height);
  grid-row: 1 / -1;
}

.main__wrapper {
  max-width: 1000px;
  margin: 0 auto;
}